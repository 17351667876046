import React from "react"
import { graphql } from "gatsby"
//import Author from "../components/author"
import BlocksRenderer from "../components/blocks-renderer"
import Seo from "../components/seo"
import Headings from "../components/headings"
import Hero from "../components/hero"
//import ShareButtons from "../components/share-buttons"
//import CtaSlider from "../components/cta-slider"
import { Helmet } from "react-helmet"

const ArticlePage = ({ data, pageContext }) => {
  //console.log("Data:", data)
  //console.log("pageContext: ", pageContext.slug)

  //const articleBlocks = data.strapiArticle.blocks
  //console.log("articleBlocks:", articleBlocks)

  const article = data.strapiArticle
  //console.log("Article:", article)
  //console.log("Article image:", article.cover.localFile.url)

  //const author = data.strapiArticle.author
  //console.log("Author:", author)

  //const callToAction = data.strapiNews.callToAction
  //console.log("callToAction: ", callToAction)

  // const callToActionSections =
  //   data.strapiNews.callToAction.call_to_action_sections
  //console.log("callToActionSections: ", callToActionSections)

  //console.log("Article - seo:", article.seo)

  const imageUrl = `${article.seo.metaImage.localFile.publicURL
  }`
  //console.log("SEO: ", Seo)

  //const quote = "Please share this article"
  //const hashtag = "#SaveTheStreetPooches"
  //const url = `${process.env.SITE_URL}${"article/" + pageContext.slug}`

  const structuredData = article.seo.structuredData
  //console.log("structuredData: ", structuredData)


  //console.log("url: ", url)

  return (
    <div as="article">
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "article",
            structuredData,
          })}
        </script>
      </Helmet>

      <Seo
        title={article.seo.metaTitle}
        description={article.seo.metaDescription}
        image={imageUrl}
        post={true}
        //structuredData={article.seo.structuredData}
        metaRobots={article.seo.metaRobots}
        metaSocial={article.seo.metaSocial}
      />

      <Hero hero={article.hero} />

      <div className="uk-container">
        <Headings
          title={article.headings.title}
          description={article.headings.description}
        />
      </div>

      <BlocksRenderer blocks={article.blocks || []} className="uk-margin-medium-top" />

      {/* make ShareButtons into bvlock component add to article template */}

      {/* <ShareButtons
        url={url}
        seo={article.seo}
        quote={quote}
        hashtag={hashtag}
      /> */}

      {/* make Author into bvlock component add to article template */}

      {/* <Author author={author} /> */}
    </div>
  )
}

export const pageQuery = graphql`
  query ($slug: String) {
    strapiArticle(slug: { eq: $slug }) {
      id
      slug
      # title
      # description
      headings {
        title
        description
      }
      blocks {
        ...Blocks
      }
      cover {
        localFile {
          publicURL
          url
          childImageSharp {
            gatsbyImageData(
              formats: AUTO
              layout: CONSTRAINED
              placeholder: BLURRED
              aspectRatio: 1.5
            )
          }
        }
      }
      author {
        name
        email
        position
        avatar {
          localFile {
            url
            publicURL
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                width: 60
                height: 60
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
        articles {
          # title
          headings {
            title
          }
          slug
        }
      }
      hero {
        ...StrapiHero
      }
      seo {
        ...StrapiSeo
        metaSocial {
          socialNetwork
          title
          description
        }
        structuredData {
          #updatedAt
          author {
            _type
            name
            email
            position
            avatar {
              alternativeText
              caption
              url
            }
          }
          advert {
            alternativeText
            caption
            url
          }
          datePublished
        }
        metaRobots
      }
    }
    strapiNews {
      callToAction {
        ...StrapiCallToAction
      }
    }
  }
`

export default ArticlePage
